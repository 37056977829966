

@font-face {
  font-family: 'outfit-bold';
  src: local('outfit'), url(./component/Fonts/JosefinSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'outfit-extrabold';
  src: local('outfit'), url(./component/Fonts/HKGrotesk-ExtraBold.otf) format('truetype');
}
@font-face {
  font-family: 'outfit-light';
  src: local('outfit'), url(./component/Fonts/JosefinSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'outfit-semibold';
  src: local('outfit'), url(./component/Fonts/JosefinSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'outfit-mediumlegacy';
  src: local('outfit'), url(./component/Fonts/HKGrotesk-MediumLegacy.otf) format('truetype');
}

@font-face {
  font-family: 'outfit-medium';
  src: local('outfit'), url(./component/Fonts/JosefinSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'outfit-regular';
  src: local('outfit'), url(./component/Fonts/JosefinSans-Regular.ttf) format('truetype');
}


